import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

interface ITokenModule {
  _token: string;
  _tokenB: string;
  _reload: boolean;
}

@Module({
  dynamic: true,
  store,
  name: "token",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).token,
})
class TokenModule extends VuexModule implements ITokenModule {
  public _token = "";

  public _tokenB = "";

  public _reload = false;

  /**
   * 変更
   * @param token
   */
  @Mutation
  public changeToken(token: string) {
    this._token = token;
  }

  /**
   * 変更
   * @param token
   */
  @Mutation
  public changeTokenB(token: string) {
    this._tokenB = token;
  }

  /**
   * 変更
   * @param reload
   */
  @Mutation
  public changeReload(reload: boolean) {
    this._reload = reload;
  }

  public get token() {
    return this._token || "";
  }

  public get tokenB() {
    return this._tokenB || "";
  }

  public get reload() {
    return this._reload || false;
  }
}
export default getModule(TokenModule);
