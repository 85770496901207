



















































































































































































































































































































































































































































































import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import axios from "axios";
import { Db } from "@/firebase/db";
import TokenModule from "@/store/token";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { uuid } from "vue-uuid";
import { Count } from "../count.d";

@Component({
  components: {
    StripeCheckout,
  },
})
export default class Home extends Vue {
  @Ref()
  checkoutRef!: StripeCheckout;

  private en = false;

  private get token() {
    return TokenModule.token;
  }

  private inputToken = "";

  private key = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

  private loading = false;

  private errorShow = false;

  private removeLevel = false;

  private lineItems = [
    {
      price: "price_1KuEp3IkK7VZbdvhNjNC5GLw", // The id of the one-time price you created in your Stripe dashboard
      quantity: 1,
    },
  ];

  private id = uuid.v4();

  private successURL = `https://stepn-mint-simulator.gaasuu.dev/thanks/${this.id}`;

  private buy() {
    this.checkoutRef.redirectToCheckout();
  }

  private async submit() {
    const querySnapshot = await Db.collection("users").where("code", "==", this.inputToken).get();
    if (querySnapshot.size > 0) {
      TokenModule.changeToken(this.inputToken);
    } else {
      this.errorShow = true;
      setTimeout(() => {
        this.errorShow = false;
      }, 5000);
    }
  }

  private countData = Db.collection("mint").doc("simulator");

  private gst = 0;

  private sol = 0;

  private gmt = 0;

  private show = false;

  private gstTrade = 0;

  private solTrade = 0;

  private gmtTrade = 0;

  private gstCost = 0;

  private gmtCost = 0;

  private get costTotal() {
    if (this.removeLevel) {
      return Number(this.gstCost) * this.gst + Number(this.gmtCost) * this.gmt;
    }

    return (Number(this.gstCost) + 20) * this.gst + (Number(this.gmtCost) + 10) * this.gmt;
  }

  private copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, 1500);
  }

  private length: Count = {
    count: 0,
  };

  private parent1 = { label: "common mint 0~1/7", gst: 60, gmt: 40 };

  private parent2 = { label: "common mint 0~1/7", gst: 60, gmt: 40 };
  // private parent1 = { label: "common mint 0~1/7 GMT only", gst: 0.01, gmt: 100 };

  // private parent2 = { label: "common mint 0~1/7 GMT only", gst: 0.01, gmt: 100 };

  private commonFloor = null;

  private uncommonFloor = null;

  private rareFloor = null;

  // private mintBase3 = [
  //   // { label: "common mint 0~1/7 GMT only", gst: 0.01, gmt: 100 },
  //   // { label: "common mint 2/7 GMT only", gst: 0.01, gmt: 150 },
  //   // { label: "common mint 3/7 GMT only", gst: 0.01, gmt: 200 },
  //   // { label: "common mint 4/7 GMT only", gst: 0.01, gmt: 250 },
  //   // { label: "common mint 5/7 GMT only", gst: 0.01, gmt: 280 },
  //   // { label: "uncommon mint 0~1/7 GMT only", gst: 0.01, gmt: 400 },
  //   // { label: "uncommon mint 2/7 GMT only", gst: 0.01, gmt: 600 },
  //   // { label: "uncommon mint 3/7 GMT only", gst: 0.01, gmt: 800 },
  //   // { label: "uncommon mint 4/7 GMT only", gst: 0.01, gmt: 1000 },
  //   // { label: "uncommon mint 5/7 GMT only", gst: 0.01, gmt: 1200 },
  //   // { label: "uncommon mint 6/7 GMT only", gst: 0.01, gmt: 1500 },
  //   // $3 < GST < $4
  //   { label: "common mint 0~1/7", gst: 50, gmt: 50 },
  //   { label: "common mint 2/7", gst: 75, gmt: 75 },
  //   { label: "common mint 3/7", gst: 100, gmt: 100 },
  //   { label: "common mint 4/7", gst: 125, gmt: 125 },
  //   { label: "common mint 5/7", gst: 140, gmt: 140 },
  //   { label: "uncommon mint 0~1/7", gst: 200, gmt: 200 },
  //   { label: "uncommon mint 2/7", gst: 300, gmt: 300 },
  //   { label: "uncommon mint 3/7", gst: 400, gmt: 400 },
  //   { label: "uncommon mint 4/7", gst: 500, gmt: 500 },
  //   { label: "uncommon mint 5/7", gst: 600, gmt: 600 },
  //   { label: "uncommon mint 6/7", gst: 750, gmt: 750 },
  //   { label: "rare mint 0~1/7", gst: 1000, gmt: 1000 },
  // ];
  // private mintBase4 = [
  //   // { label: "common mint 0~1/7 GMT only", gst: 0.01, gmt: 100 },
  //   // { label: "common mint 2/7 GMT only", gst: 0.01, gmt: 150 },
  //   // { label: "common mint 3/7 GMT only", gst: 0.01, gmt: 200 },
  //   // { label: "common mint 4/7 GMT only", gst: 0.01, gmt: 250 },
  //   // { label: "common mint 5/7 GMT only", gst: 0.01, gmt: 280 },
  //   // { label: "uncommon mint 0~1/7 GMT only", gst: 0.01, gmt: 400 },
  //   // { label: "uncommon mint 2/7 GMT only", gst: 0.01, gmt: 600 },
  //   // { label: "uncommon mint 3/7 GMT only", gst: 0.01, gmt: 800 },
  //   // { label: "uncommon mint 4/7 GMT only", gst: 0.01, gmt: 1000 },
  //   // { label: "uncommon mint 5/7 GMT only", gst: 0.01, gmt: 1200 },
  //   // { label: "uncommon mint 6/7 GMT only", gst: 0.01, gmt: 1500 },
  //   // $4 < GST < $8
  //   { label: "common mint 0~1/7", gst: 50, gmt: 50 },
  //   { label: "common mint 2/7", gst: 75, gmt: 75 },
  //   { label: "common mint 3/7", gst: 100, gmt: 100 },
  //   { label: "common mint 4/7", gst: 125, gmt: 125 },
  //   { label: "common mint 5/7", gst: 140, gmt: 140 },
  //   { label: "uncommon mint 0~1/7", gst: 200, gmt: 200 },
  //   { label: "uncommon mint 2/7", gst: 300, gmt: 300 },
  //   { label: "uncommon mint 3/7", gst: 400, gmt: 400 },
  //   { label: "uncommon mint 4/7", gst: 500, gmt: 500 },
  //   { label: "uncommon mint 5/7", gst: 600, gmt: 600 },
  //   { label: "uncommon mint 6/7", gst: 750, gmt: 750 },
  //   { label: "rare mint 0~1/7", gst: 1000, gmt: 1000 },
  // ];

  private get reload() {
    return TokenModule.reload;
  }

  private async mounted() {
    this.getPrice();
    this.getCount();
    if (this.token === "" && !this.reload) {
      TokenModule.changeReload(true);
      window.location.reload();
    }
  }

  private async getCount() {
    const countTable = await this.countData.get();
    const data: Count = countTable.data() as Count;
    this.length = data;
  }

  @Watch("gstCost")
  @Watch("gmtCost")
  @Watch("en")
  private async simulate() {
    if ((this.gstCost && this.gmtCost && this.commonFloor) || (this.gstCost && this.gmtCost && this.uncommonFloor)) {
      await this.getCount();
      const add = this.length.count + 1;
      if (add > this.length.count) {
        this.countData.set({ count: add });
      }
      this.getCount();
    }
  }

  @Watch("en")
  private resetForm() {
    this.getPrice();
    this.parent1 = { label: "common mint 0~1/7", gst: 60, gmt: 40 };
    this.parent2 = { label: "common mint 0~1/7", gst: 60, gmt: 40 };
    // this.parent1 = { label: "common mint 0~1/7 GMT only", gst: 0.01, gmt: 100 };
    // this.parent2 = { label: "common mint 0~1/7 GMT only", gst: 0.01, gmt: 100 };
  }

  private async getPrice() {
    let url =
      "https://api.coingecko.com/api/v3/simple/price?ids=solana%2Cgreen-satoshi-token%2Cstepn&vs_currencies=jpy";
    if (this.en)
      url = "https://api.coingecko.com/api/v3/simple/price?ids=solana%2Cgreen-satoshi-token%2Cstepn&vs_currencies=usd";
    const res = await axios.get(url);
    this.gst = this.en ? res.data["green-satoshi-token"].usd : res.data["green-satoshi-token"].jpy;
    this.sol = this.en ? res.data.solana.usd : res.data.solana.jpy;
    this.gmt = this.en ? res.data.stepn.usd : res.data.stepn.jpy;
  }
}
