import Vue from "vue";
import { app } from "@/config/firebase";
import { getAnalytics } from "@firebase/analytics";
import ja from "@/assets/ja.json";
import VueAdsense from "vue-adsense";
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.component("adsense", VueAdsense);
extend("required", required);
extend("regex", regex);
localize("ja", ja);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.config.productionTip = false;

getAnalytics(app);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
